import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import ProductCardOne from "@/components/product-card-one"
import { ProductPostData } from "./data"

const ProductOne = () => {
  return (
    <section className="service_section_2 commonSection">
      <Container>
        <Row className="justify-content-md-center">
          {ProductPostData.map((post, index) => (
            <Col lg={4} md={12} key={index}>
              <ProductCardOne data={post} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default ProductOne
