import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import PageBanner from "@/components/page-banner";
import ProductOne from "@/components/product-one";
import Footer from "@/components/footer-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";

const FeaturePage = () => { 
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <Layout PageTitle="Feature">
                    <HeaderOne/>
                    <PageBanner title="Features" name="Features" />
                    <ProductOne/>
                    <Footer/>
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default FeaturePage