import cricketIcon from "@/images/icons/cricket.svg"
import rugbyIcon from "@/images/icons/rugby.svg"
import soccerIcon from "@/images/icons/soccer.svg"

export const ProductPostData = [
  {
    title: "Cricket",
    text: "Cloud-based LIVE sports League Management platform to easily manage events, matches, teams and players. Your events and matches - your way!",
    icon: [cricketIcon],
    btn_txt: "Discover More",
    url: "/cricket",
  },
  {
    title: "Football",
    text: "LIVE football match scoring made easy. Cloud native and realtime solution for football clubs and organisations.",
    icon: [soccerIcon],
    btn_txt: "Coming Soon",
    url: "#",
  },
  {
    title: "Rugby",
    text: "LIVE Rubgy scoring via Cloud based web and mobile apps. View real time scores anywhere.",
    icon: [rugbyIcon],
    btn_txt: "Coming Soon",
    url: "#",
  },
]
