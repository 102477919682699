import React from "react"
import { Link } from "gatsby"

const ProductCardOne = ({ data }) => {
  const { title, text, icon, btn_txt, url } = data
  return (
    <div className="icon_box_2 text-center">
      <h3>{title}</h3>
      <p>{text}</p>
      <div className="iconWrap">
        <img src={icon} alt="cricket-icn" />
      </div>
      <Link to={url}>
        <button className="sign-up-btn" type="submit" id={`con_submit_${title}`}>
          <span>{btn_txt}</span>
        </button>
      </Link>
    </div>
  )
}

export default ProductCardOne
